<template>
  <form method="POST" @submit.prevent="send" @keydown="form.onKeydown($event)">

    <div class="container d-flex justify-content-center ht-100p">
      <div class="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
        <div class="wd-80p wd-sm-300 mg-b-15"><img :src="src" class="img-fluid w-100" alt=""></div>
        <h4 class="tx-20 tx-sm-24 tx-center">{{ ('Reset your password') }}</h4>
        <p class="tx-color-03 mg-b-30 tx-center">{{ ('Enter your email address and we will send you a link to reset your password.') }}</p>

        <alert-success class="wd-100p" :form="form" :message="status" />

        <div class="wd-100p d-flex flex-column flex-sm-row mg-b-20">
          <input id="email" type="email" v-model="form.email" class="form-control wd-sm-250 flex-fill" :class="{ 'is-invalid': form.errors.has('email') }" name="email" required autocomplete="email" autofocus placeholder="Enter username or email address">
          <button class="btn btn-brand-02 mg-sm-l-10 mg-t-10 mg-sm-t-0">{{ ('Reset Password') }}</button>
        </div>
        <div class="wd-100p d-flex flex-column flex-sm-row mg-b-40">
          <has-error :form="form" field="email" />
        </div>

      </div>
    </div><!-- container -->
  </form>
</template>

<script>
  import Form from 'vform'

  export default {
    middleware: 'guest',
    data(){
      return {
        status: '',
        form: new Form({
          email: ""
        }),
        src: "/images/login-02.svg"
      }
    },
    created () {
      this.form.email = this.$route.query.email;
    },
    methods: {
      async send () {
        const { data } = await this.form.post('/api/v1/auth/password/email');

        this.status = data.message;

        this.form.reset();
      }
    }
  }
</script>